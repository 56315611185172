import { Dialog, Transition } from '@headlessui/react';
import {
  AdjustmentsHorizontalIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid';
import type { ReturnedRow } from 'database/bigQueryHelper';
import type { Filter, FilterAttributes } from 'database/queryHelper';
import { filter, isEmpty } from 'lodash';
import { useRouter } from 'next/router';
import { Fragment, useContext, useEffect, useState } from 'react';
import FilterMultiSelect from 'ui/FilterMultiSelect';
import { QueriesFiltersContext } from './state/state';

type Props = {
  closeModal: () => void;
};

export default function ModalFilter({ closeModal }: Props) {
  const router = useRouter();
  const [collapseOpen, setCollapseOpen] = useState(false);
  const [count, setCount] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState<FilterAttributes[]>([]);
  const { queries, filtersList, addFilterCount } = useContext(
    QueriesFiltersContext
  );
  const [filterEntreprise] = useState<Filter[]>(() => {
    const data = filter(
      filtersList,
      (obj: Filter) => obj.type === 'filtre entreprise'
    );
    return data;
  });
  const [filterFormation] = useState<Filter[]>(() => {
    const data = filter(
      filtersList,
      (obj: Filter) => obj.type === 'filtre formation'
    );
    return data;
  });
  const [filterSalaries] = useState<Filter[]>(() => {
    const data = filter(
      filtersList,
      (obj: Filter) => obj.type === 'filtre salarié'
    );
    return data;
  });

  const handleResetAllFilters = () => {
    void router.push({
      pathname: router.pathname,
      query: {},
    });
    setCount(0);
    addFilterCount(0);
    closeModal();
  };

  const handleRemoveFilter = (filter: FilterAttributes) => {
    const updatedFilters = selectedFilter.filter(
      (f) => f.id !== filter.id || f.value !== filter.value
    );
    setCount(updatedFilters.length);
    setSelectedFilter(updatedFilters);
  };

  const handleApplyFilters = () => {
    const updatedFilterToApply: Record<string, string> = {};
    selectedFilter.forEach((f) => {
      const id = f.id as string;
      // separate values by comma
      if (updatedFilterToApply[id] && f.value) {
        updatedFilterToApply[id] =
          `${updatedFilterToApply[id]},${f.value.toString()}`;
      } else {
        updatedFilterToApply[id] = f.value as string;
      }
      if (router.query.search) {
        updatedFilterToApply.search = router.query.search as string;
      }
    });
    void router.push({
      pathname: router.pathname,
      query: updatedFilterToApply,
    });
    setCount(selectedFilter.length);
    addFilterCount(selectedFilter.length);
    closeModal();
  };

  const handleChangeMultiSelect = (event: ReturnedRow[], field: Filter) => {
    let updatedFilters: FilterAttributes[] = [...selectedFilter];
    if (!isEmpty(event)) {
      // check if element exist in event and not exist in updatedFilters then add it to updatedFilters
      event.forEach((element: ReturnedRow) => {
        const existingFilter = updatedFilters.find(
          (f) => f.id === field.id && f.value === element[field.id]
        );
        if (!existingFilter) {
          const newFilter: FilterAttributes = {
            id: field.id as string,
            label: field.label as string,
            ref: field.ref as string,
            name: element[`${field.label?.toString()}`] as string,
            value: element[field.id as string] as string | number,
            is_applied: false,
          };
          updatedFilters.push(newFilter);
        }
      });

      // check if element not exist in event and exist in updatedFilters then remove it from updatedFilters
      updatedFilters = updatedFilters.filter((f) => {
        const existingElement = event.find(
          (element) => element[field.id as string] === f.value
        );
        return existingElement ?? f.id !== field.id;
      });
    } else {
      // If no elements are selected, remove the entire filter for this field
      updatedFilters = updatedFilters.filter((f) => {
        return f.id !== field.id;
      });
    }
    setSelectedFilter(updatedFilters);
    setCount(updatedFilters.length);
  };

  useEffect(() => {
    const newSelectedFilter: FilterAttributes[] = filtersList
      .map((filter) => {
        const data = queries[`${filter.ref}`]?.returnedRows ?? [];
        const values = filter.value ?? [];
        const selectedFilters: FilterAttributes[] = [];
        if (Array.isArray(values)) {
          values.forEach((value: string | number) => {
            const row = data.find((d: ReturnedRow) => {
              if (d[`${filter.id}`]) {
                return d[`${filter.id}`]?.toString() === value.toString();
              }
              return false;
            });
            if (row) {
              selectedFilters.push({
                id: filter.id as string,
                label: filter.label as string,
                ref: filter.ref as string,
                name: row[`${filter.label}`] as string,
                value: value,
                is_applied: true,
              });
            }
          });
        } else {
          const row = data.find((d: ReturnedRow) => {
            if (d[`${filter.id}`]) {
              return d[`${filter.id}`]?.toString() === values.toString();
            }
            return false;
          });
          if (row) {
            selectedFilters.push({
              id: filter.id as string,
              label: filter.label as string,
              ref: filter.ref as string,
              name: row[`${filter.label}`] as string,
              value: filter.value,
              is_applied: true,
            });
          }
        }
        return selectedFilters;
      })
      .flat();
    setCount(newSelectedFilter.length);
    setSelectedFilter(newSelectedFilter);
  }, [filtersList, queries]);

  return (
    <>
      <Transition appear show as={Fragment}>
        <Dialog as='div' className='relative z-50' onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex items-center justify-center text-cente'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='transform px-28 py-10 space-y-4 w-screen h-screen overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all scrollbar-thin scrollbar-thumb-gray-900 scrollbar-thumb-rounded-full'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    <div className='text-black flex justify-between'>
                      <span className='text-3xl font-bold'>Filtres</span>
                      <button
                        className='rounded-md border hover:bg-slate-300'
                        onClick={closeModal}
                      >
                        <XMarkIcon className='h-10 w-10' />
                      </button>
                    </div>
                  </Dialog.Title>
                  <div>
                    <p className='text-md'>
                      Vous pouvez filtrer votre recherche en sélectionnant des
                      critères ci-après
                    </p>
                    <p className='text-sm text-gray-500'>
                      Ci-dessous, la liste des filtres et aggrégations; en bleu
                      ciel, sont les filtres qui vont être ajoutés et en gris,
                      les filtres déjà appliqués.
                    </p>
                    <p className='text-sm text-gray-500'>
                      {
                        "N'oubliez pas d'appuyer sur le bouton « Valider » pour que les critères sélectionnés soient appliqués"
                      }
                    </p>
                  </div>
                  <div className='flex justify-between'>
                    <div className='min-w-0 flex'>
                      <div className='flex justify-center self-center'>
                        <div className='text-center '>
                          <TrashIcon
                            onClick={handleResetAllFilters}
                            className='mx-auto h-6 w-6 hover:text-[#EB5401] cursor-pointer'
                            aria-hidden='true'
                          />
                        </div>
                      </div>
                      <div className='flex mx-5'>
                        <button
                          onClick={closeModal}
                          type='button'
                          className='inline-block rounded border border-yellow-500 bg-yellow-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-yellow-500 focus:outline-none focus:ring active:text-yellow-500'
                        >
                          Annuler
                        </button>
                      </div>
                    </div>
                    <div className='flex'>
                      <button
                        onClick={handleApplyFilters}
                        type='button'
                        className='inline-block rounded border border-green-500 bg-green-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-green-500 focus:outline-none focus:ring active:text-green-500'
                      >
                        Valider
                      </button>
                    </div>
                  </div>
                  {count > 0 ? (
                    <div>
                      <div
                        id='accordion-collapse-secteur-naf'
                        data-accordion='collapse'
                      >
                        <h2
                          id='accordion-collapse-heading-1'
                          className='rounded-lg'
                        >
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setCollapseOpen(!collapseOpen);
                            }}
                            type='button'
                            className='bg-gray-50 flex items-center justify-between w-full py-2 px-5 font-medium text-left text-gray-500 rounded'
                            data-accordion-target='#accordion-collapse-body-1'
                            aria-expanded='true'
                            aria-controls='accordion-collapse-body-1'
                          >
                            <div className='flex'>
                              <AdjustmentsHorizontalIcon className='w-6 h-6 text-gray-400' />
                              <span className='px-2'>Filtres actifs</span>
                              <span className='inline-flex items-center justify-center w-5 h-5 ml-2 text-xs font-semibold text-white bg-red-500 rounded-full'>
                                {count}
                              </span>
                            </div>
                            {!collapseOpen ? (
                              <ChevronUpIcon className='w-6 h-6 rotate-180 shrink-0' />
                            ) : (
                              <ChevronDownIcon className='w-6 h-6 rotate-180 shrink-0' />
                            )}
                          </button>
                        </h2>
                        <div
                          id='accordion-collapse-body-1'
                          className={`${collapseOpen ? '' : 'hidden'} rounded-lg`}
                          aria-labelledby='accordion-collapse-heading-1'
                        >
                          <div className='bg-gray-50 p-5 font-light'>
                            <div className='flex flex-row overflow-y-auto maw-h-48'>
                              {selectedFilter.map(
                                (filter: FilterAttributes, index: number) => (
                                  <div
                                    key={index}
                                    className={`flex flex-row shadow-md relative rounded-md px-2 py-0.5 text-xs text-slate-700  mx-1 mb-1 max-w-2xl ${
                                      filter.is_applied
                                        ? 'bg-gray-100'
                                        : 'bg-blue-100'
                                    }`}
                                  >
                                    <p className='self-center font-semibold text-oca-blue-100'>
                                      {filter.name}
                                    </p>

                                    <span className='text-gray-500 font-semibold pl-1 cursor-pointer self-center'>
                                      <XMarkIcon
                                        className='h-3 w-3'
                                        aria-hidden='true'
                                        onClick={() => {
                                          handleRemoveFilter(filter);
                                        }}
                                      />
                                    </span>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className='rounded border shadow-md'>
                    <div className='bg-indigo-900 text-white py-4 px-3 rounded-t-lg'>
                      <div className='text-sm font-medium uppercase'>
                        Entreprise
                      </div>
                    </div>
                    <div className='p-3'>
                      <div className='grid grid-flow-col max-w-12xl mx-auto pt-2 pb-2 px-6 lg:px-8 '>
                        <div className='flex flex-row'>
                          <div className='flex flex-wrap gap-4 lg:justify-start justify-center mt-1.5 w-'>
                            {filterEntreprise.map(
                              (filter: Filter, idx: number) => {
                                return (
                                  <div key={idx.toString()}>
                                    <div className='w-full flex justify-center'>
                                      <div className='relative'>
                                        <FilterMultiSelect
                                          queryWithResults={
                                            queries[filter.ref as string]!
                                          }
                                          field={{
                                            id: filter.id as string,
                                            label: filter.label as string,
                                          }}
                                          width={'w-80'}
                                          filterColor='[#EB5401]'
                                          border
                                          handleChange={(
                                            event: ReturnedRow[]
                                          ) => {
                                            handleChangeMultiSelect(
                                              event,
                                              filter
                                            );
                                          }}
                                          selected={queries[
                                            filter.ref as string
                                          ]?.returnedRows?.filter(
                                            (data: ReturnedRow) => {
                                              const selectedValues =
                                                selectedFilter
                                                  .filter(
                                                    (f: FilterAttributes) => {
                                                      return f.id === filter.id;
                                                    }
                                                  )
                                                  .map((f: FilterAttributes) =>
                                                    f.value?.toString()
                                                  );
                                              return selectedValues.includes(
                                                data[
                                                  filter.id as string
                                                ]?.toString()
                                              );
                                            }
                                          )}
                                          tags={{
                                            selected: selectedFilter,
                                            handleChange: handleRemoveFilter,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='rounded border shadow-md'>
                    <div className='bg-indigo-900 text-white py-4 px-3 rounded-t-lg'>
                      <div className='text-sm font-medium uppercase'>
                        Formation
                      </div>
                    </div>
                    <div className='p-3 w-full'>
                      <div className='grid grid-flow-col max-w-12xl mx-auto pt-2 pb-2 px-6 lg:px-8 '>
                        <div className='flex flex-row'>
                          <div className='flex flex-wrap gap-4 lg:justify-start justify-center mt-1.5 w-'>
                            {filterFormation.map(
                              (filter: Filter, idx: number) => {
                                return (
                                  <div key={idx.toString()}>
                                    <div className='w-full flex justify-center'>
                                      <div className='relative'>
                                        <FilterMultiSelect
                                          queryWithResults={
                                            queries[filter.ref as string]!
                                          }
                                          field={{
                                            id: filter.id as string,
                                            label: filter.label as string,
                                          }}
                                          width={'w-80'}
                                          filterColor='[#EB5401]'
                                          border
                                          handleChange={(
                                            event: ReturnedRow[]
                                          ) => {
                                            handleChangeMultiSelect(
                                              event,
                                              filter
                                            );
                                          }}
                                          selected={queries[
                                            filter.ref as string
                                          ]?.returnedRows?.filter(
                                            (data: ReturnedRow) => {
                                              const selectedValues =
                                                selectedFilter
                                                  .filter(
                                                    (f: FilterAttributes) => {
                                                      return f.id === filter.id;
                                                    }
                                                  )
                                                  .map((f: FilterAttributes) =>
                                                    f.value?.toString()
                                                  );
                                              return selectedValues.includes(
                                                data[
                                                  filter.id as string | number
                                                ]?.toString()
                                              );
                                            }
                                          )}
                                          tags={{
                                            selected: selectedFilter,
                                            handleChange: handleRemoveFilter,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='rounded border shadow-md'>
                    <div className='bg-indigo-900 text-white py-4 px-3 rounded-t-lg'>
                      <div className='text-sm font-medium uppercase'>
                        Salariés
                      </div>
                    </div>
                    <div className='p-3 w-full'>
                      <div className='grid grid-flow-col max-w-12xl mx-auto pt-2 pb-2 px-6 lg:px-8 '>
                        <div className='flex flex-row'>
                          <div className='flex flex-wrap gap-4 lg:justify-start justify-center mt-1.5 w-'>
                            {filterSalaries.map(
                              (filter: Filter, idx: number) => {
                                return (
                                  <div key={idx.toString()}>
                                    <div className='w-full flex justify-center'>
                                      <div className='relative'>
                                        <FilterMultiSelect
                                          queryWithResults={
                                            queries[filter.ref as string]!
                                          }
                                          field={{
                                            id: filter.id as string,
                                            label: filter.label as string,
                                          }}
                                          width={'w-80'}
                                          filterColor='[#EB5401]'
                                          border
                                          handleChange={(
                                            event: ReturnedRow[]
                                          ) => {
                                            handleChangeMultiSelect(
                                              event,
                                              filter
                                            );
                                          }}
                                          selected={queries[
                                            filter.ref as string
                                          ]?.returnedRows?.filter(
                                            (data: ReturnedRow) => {
                                              const selectedValues =
                                                selectedFilter
                                                  .filter(
                                                    (f: FilterAttributes) => {
                                                      return f.id === filter.id;
                                                    }
                                                  )
                                                  .map((f: FilterAttributes) =>
                                                    f.value?.toString()
                                                  );
                                              return selectedValues.includes(
                                                data[
                                                  filter.id as string
                                                ]?.toString()
                                              );
                                            }
                                          )}
                                          tags={{
                                            selected: selectedFilter,
                                            handleChange: handleRemoveFilter,
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className='text-xs'>
                      {
                        "Ci-dessus, la liste des filtres et aggrégations; en bleu ciel, sont les filtres qui vont être ajoutés et en gris, les filtres déjà appliqués. N'oubliez pas d'appuyer sur le bouton « Valider » pour que les critères sélectionnés soient appliqués"
                      }
                    </p>
                    <div className='flex justify-between  pt-4'>
                      <div className='min-w-0 flex'>
                        <div className='flex justify-center self-center'>
                          <div className='text-center '>
                            <TrashIcon
                              onClick={handleResetAllFilters}
                              className='mx-auto h-6 w-6 hover:text-[#521E3E] cursor-pointer'
                              aria-hidden='true'
                            />
                          </div>
                        </div>
                        <div className='flex mx-5'>
                          <button
                            onClick={closeModal}
                            type='button'
                            className='inline-block rounded border border-yellow-500 bg-yellow-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-yellow-500 focus:outline-none focus:ring active:text-yellow-500'
                          >
                            Annuler
                          </button>
                        </div>
                      </div>
                      <div className='flex'>
                        <button
                          onClick={handleApplyFilters}
                          type='button'
                          className='inline-block rounded border border-green-500 bg-green-500 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-green-500 focus:outline-none focus:ring active:text-green-500'
                        >
                          Valider
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
